export const SET_FEEDS = "SET_FEEDS";
export const SET_LOADING_FEEDS = "SET_LOADING_FEEDS";
export const SET_LOADING_ADD_UPDATE_FEED = "SET_LOADING_ADD_UPDATE_FEED";
export const SET_ADD_FEED_SUCCESS = "SET_ADD_FEED_SUCCESS";
export const SET_UPDATE_FEED_SUCCESS = "SET_UPDATE_FEED_SUCCESS";
export const LOADING_DELETE_FEED = "LOADING_DELETE_FEED";
export const DELETE_FEED_SUCCESS = "DELETE_FEED_SUCCESS";

export const SET_LOADING_SLIDE_SHOW = "SET_LOADING_SLIDE_SHOW";
export const SET_SLIDE_SHOW = "SET_SLIDE_SHOW";
//recent slide show status update
export const SET_LOADING_RECENT_SLIDE_SHOW_STATUS =
  "SET_LOADING_RECENT_SLIDE_SHOW_STATUS";
export const SET_RECENT_SLIDE_SHOW_STATUS = "SET_RECENT_SLIDE_SHOW_STATUS";
//list slide show status update
export const SET_LOADING_SLIDE_SHOW_STATUS = "SET_LOADING_SLIDE_SHOW_STATUS";
export const SET_SLIDE_SHOW_STATUS = "SET_SLIDE_SHOW_STATUS";

export const SET_SLIDESHOW_SCHEDULE_ACTIVE_DATE_TIME =
  "SET_SLIDESHOW_SCHEDULE_ACTIVE_DATE_TIME";

export const LOADING_DELETE_SLIDESHOW = "LOADING_DELETE_SLIDESHOW";
export const DELETE_SLIDESHOW_SUCCESS = "DELETE_SLIDESHOW_SUCCESS";

export const LOADING_UPDATE_SLIDESHOW = "LOADING_UPDATE_SLIDESHOW";
export const SET_SLIDE_TITLE = "SET_SLIDE_TITLE";
export const UPDATE_SLIDESHOW_SUCCESS = "UPDATE_SLIDESHOW_SUCCESS";

export const LOADING_COPY_SLIDESHOW = "LOADING_COPY_SLIDESHOW";
export const COPY_SLIDESHOW = "COPY_SLIDESHOW";

export const LOADING_ACTIVE_DE_ACTIVE = "LOADING_ACTIVE_DE_ACTIVE";
export const PUBLISHED = "PUBLISHED";

export const SET_MOVE_SLIDESHOW = "SET_MOVE_SLIDESHOW";
export const SET_LOADING_MOVE_SLIDESHOW = "SET_LOADING_MOVE_SLIDESHOW";

export const LOADING_ADD_GROW_SLIDESHOW = "LOADING_ADD_GROW_SLIDESHOW";
export const LOADING_ADD_SLIDESHOW = "LOADING_ADD_SLIDESHOW";

export const FETCH_FEED_SLIDES = "FETCH_FEED_SLIDES";
export const FETCH_FEED_SLIDES_LOADING = "FETCH_FEED_SLIDES_LOADING";

//for displaying counts in grow slideshow
export const FETCH_USER_VOLUMES_COUNTS = "FETCH_USER_VOLUMES_COUNTS";

export const SHARE_DASHBOARD_ACCESS = "SHARE_DASHBOARD_ACCESS";

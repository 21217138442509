export const SET_SLIDES = "SET_SLIDES";

export const SET_ACTIVE_SLIDE = "SET_ACTIVE_SLIDE";
export const SET_SLIDE = "SET_SLIDE";
export const SET_DELETE_SLIDE = "SET_DELETE_SLIDE";
export const SET_ACTIVE_FIELD = "SET_ACTIVE_FIELD";
export const SET_TEXT_FIELD_VALUE = "SET_TEXT_FIELD_VALUE";

export const SET_TEXT_FIELD_DELETE = "SET_TEXT_FIELD_DELETE";
export const SET_TEXT_FIELD_COPY = "SET_TEXT_FIELD_COPY";
export const SET_TEXT_FIELD_SET_TO_TOP = "SET_TEXT_FIELD_SET_TO_TOP";
export const SET_TEXT_FIELD_SET_TO_BACK = "SET_TEXT_FIELD_SET_TO_BACK";

export const ADD_TEXT = "ADD_TEXT";
export const ADD_IMAGE = "ADD_IMAGE";
export const SET_IMAGE_FIELD_VALUE = "SET_IMAGE_FIELD_VALUE";
export const SET_IS_IMAGE_SELECT = "SET_IS_IMAGE_SELECT";

export const SET_VIDEO_FIELD = "SET_VIDEO_FIELD";
export const REPLACE_VIDEO_FIELD = "REPLACE_VIDEO_FIELD";
export const SET_BACKGROUND_FIELD = "SET_BACKGROUND_FIELD";
export const SET_MODAL_PREVIEW = "SET_MODAL_PREVIEW";
export const SET_GIF_FIELD = "SET_GIF_FIELD";

export const SET_SLIDE_SETTINGS = "SET_SLIDE_SETTINGS";
export const SET_LOADING_SLIDE_SETTINGS = "SET_LOADING_SLIDE_SETTINGS";

export const SET_GLOBAL_COPY_FIELD = "SET_GLOBAL_COPY_FIELD";
export const SET_GLOBAL_PASTE_FIELD = "SET_GLOBAL_PASTE_FIELD";

export const SLIDR_SLIDES_CHANGE = "SLIDR_SLIDES_CHANGE";
export const SLIDR_ACTION_UNDO = "SLIDR_ACTION_UNDO";
export const SLIDR_ACTION_REDO = "SLIDR_ACTION_REDO";

export const SET_SLIDESHOW = "SET_SLIDESHOW";
export const SLIDESHOW_TITLE = "SLIDESHOW_TITLE";

export const SET_LOADING_PUBLISH_SLIDESHOW = "SET_LOADING_PUBLISH_SLIDESHOW";
export const SET_PUBLISHED_DATE_TIME = "SET_PUBLISHED_DATE_TIME";
export const SET_SLIDE_TITLE = "SET_SLIDE_TITLE";
export const CLEAR_SLIDES_STATE = "CLEAR_SLIDES_STATE";

export const SET_LOADING_DELETE_SLIDE = "SET_LOADING_DELETE_SLIDE";
export const SET_COPY_SLIDE = "SET_COPY_SLIDE";
export const SET_LOADING_COPY_SLIDE = "SET_LOADING_COPY_SLIDE";
export const SET_LOADING_SAVE_SLIDESHOW = "SET_LOADING_SAVE_SLIDESHOW";

export const INCREMENT_COUNT = "INCREMENT_COUNT";
export const DECREMENT_COUNT = "DECREMENT_COUNT";

export const LOADING_GET_SLIDESHOW = "LOADING_GET_SLIDESHOW";

export const SET_SLIDE_IMAGES = "SET_SLIDE_IMAGES";

export const SET_SLIDE_MUSIC = "SET_SLIDE_MUSIC";
export const SET_ACTIVE_PLAYLIST = "SET_ACTIVE_PLAYLIST";
export const SET_ACTIVE_HISTORY = "SET_ACTIVE_HISTORY";

export const SET_SAVE_PLAYLIST_LOADING = "SET_SAVE_PLAYLIST_LOADING";
export const SET_PLAYLIST = "SET_PLAYLIST";

export const SAVE_SLIDE_HISTORY = "SAVE_SLIDE_HISTORY";
export const FETCH_HISTORY_DATA = "FETCH_HISTORY_DATA";
export const DELETE_SLIDESHOW_HISTORY = "DELETE_SLIDESHOW_HISTORY";

export const SET_SAVE_MESSAGE_SLIDE = "SET_SAVE_MESSAGE_SLIDE";
